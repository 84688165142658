<template>
  <div id="visitor" data-aos="fade-up" data-aos-delay="0" data-aos-duration="1000">
    <div class="page-title-area padding-50-pr bg-6">
      <div class="container">
        <div class="page-title-content">
          <h2>Get Involved</h2>
        </div>
      </div>
    </div>

    <div class="loading" style="height: 700px;" v-if="visitor_data === undefined && !visitor_error" >
      <Loading />
    </div>
    <VisitorContent v-else-if="visitor_data" :visitor_data="visitor_data.data" />
  </div>
</template>

<script>
  import api from '../services/api';
  import useSWRV from "swrv";
  import VisitorContent from "../components/VisitorContent";
  import Loading from "../components/Loading";
  export default {
    name: "Visitor",
    components: {
      Loading,
      VisitorContent
    },
    setup() {
      let {
        data: visitor_data,
        error: visitor_error,
        isValidating: visitor_isValidating
      } = useSWRV(() => `items/visitor?status=published&fields=id,status,text,title,visitor_type,image.*`, api.fetcher);

      return {
        visitor_data,
        visitor_error,
        visitor_isValidating
      }
    },
  }
</script>

<style scoped lang="scss">

.page-title-area {
  background-color: #fdc540;

  .page-title-content h2 {
    color: #373438;
  }
}

.line-wrap {
  white-space: pre-line;
}

.links-color {
  color: #7c71f1;
}

.about-image img{
  min-height: 400px;
  position: relative;
  background-size: cover;
  background-position: 50%;
}

  @media (max-width: 992px) {
    #our-mission .row {
      flex-direction: column-reverse;
    }

    .default-img {
      margin-top: 25px;
      height: 250px !important;
    }

    .video-section .video-frame {
      margin-top: 0;
    }

    #our-story {
      padding-bottom: 50px;
    }

    .others-section {
      background: linear-gradient( #31bfd7, #0fdbaf);
    }

    .others-section a {
      font-size: 18px;
      img {
        width: 34px;
      }
    }
    .others-section .background:before {
      content: none;
    }
  }

  .active {
    animation: pulse 1s normal;
    animation-delay: 0.5s;
  }

  @-webkit-keyframes pulse {
    0% {
      transform: scale(1.1);
    }
    100% {
      transform: scale(1);
    }
  }
  @keyframes pulse {
    0% {
      transform: scale(1.1);
    }
    100% {
      transform: scale(1);
    }
  }

  .visitor-section {
    .visitor-card {
      min-height: 400px;
      position: relative;
      background-size: cover;
      background-position: center;
      margin-bottom: 30px;

      .card-content {
        position: relative;
        z-index: 1;
        padding: 20px;

        h1 {
          color: #fff;
          font-size: 35px;
          line-height: 35px;
          position: relative;

          &:after {
            content: "";
            position: absolute;
            height: 3px;
            width: 50px;
            background-color: #ffffff;
            left: 0;
            bottom: -7px;
          }
        }

        .links-section {
          display: flex;
          flex-direction: column;
          margin-top: 20px;
          align-items: flex-start;

          a {
            color: #fff;
            font-size: 18px;
            margin-bottom: 5px;
          }
        }
      }

      &::after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: rgba(0, 0, 0, 0.61);
      }

      &.student {
        background-image: url("../assets/img/visitor/student-bg.jpg");
        border-top: 5px solid #30bfd7;
      }

      &.educator {
        background-image: url("../assets/img/visitor/educator-bg.jpg");
        border-top: 5px solid #28b076;
      }

      &.alumni {
        background-image: url("../assets/img/visitor/alumni-bg.jpg");
        border-top: 5px solid #FC4040;
      }

      &.donor {
        background-image: url("../assets/img/visitor/donor-bg.jpg");
        border-top: 5px solid #FFC107;
      }

      &.partner {
        background-image: url("../assets/img/visitor/partner.jpg");
        border-top: 5px solid #9334d7;
      }
    }
  }
</style>
