<template>
  <div class="visitorContent-wrapper">
    <section class="p-0" id="student">
      <div class="container-fluid">
        <div class="row">
          <div class="col-lg-6 d-flex align-items-center">
            <div class="text-section">
              <div class="about-area-content">
                <span>Student</span>
              </div>

              <div class="links-section pt-2">
                <div class="pt-3 pb-3 line-wrap white-space-pre-wrap" v-if="getItem(1).link_description" v-html="getItem(1).link_description"></div>
                <h2>{{getItem(1).title}}</h2>
                <div v-html="getItem(1).text"></div>
              </div>
            </div>
          </div>

          <div class="col-lg-6">
            <div class="about-image" :style="`background-image: url('` + getItem(1).imageUrl + `')`"></div>
          </div>
        </div>
      </div>
    </section>

    <section class="events-section p-0" id="educator">
      <div class="container-fluid">
        <div class="row flex-lg-row flex-column-reverse">
          <div class="col-lg-6">
            <div class="about-image" :style="`background-image: url('` + getItem(2).imageUrl + `')`"></div>
          </div>
          <div class="col-lg-6 d-flex align-items-center">
            <div class="text-section">
              <div class="about-area-content">
                <span>Educator</span>
              </div>

              <div class="links-section pt-2">
                <div class="pt-3 pb-3 line-wrap white-space-pre-wrap" v-if="getItem(2).link_description" v-html="getItem(2).link_description"></div>
                <h2>{{getItem(2).title}}</h2>
                <div v-html="getItem(2).text"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="p-0" id="alumni">
      <div class="container-fluid">
        <div class="row">
          <div class="col-lg-6 d-flex align-items-center">
            <div class="text-section">
              <div class="about-area-content">
                <span>Alumni</span>
              </div>

              <div class="links-section pt-2">
                <div class="pt-3 pb-3 line-wrap white-space-pre-wrap" v-if="getItem(3).link_description" v-html="getItem(3).link_description"></div>
                <h2>{{getItem(3).title}}</h2>
                <div v-html="getItem(3).text"></div>
              </div>
            </div>
          </div>

          <div class="col-lg-6">
            <div class="about-image" :style="`background-image: url('` + getItem(3).imageUrl + `')`"></div>
          </div>
        </div>
      </div>
    </section>

    <section  class="events-section p-0" id="supporter">
      <div class="container-fluid">
        <div class="row flex-lg-row flex-column-reverse">
          <div class="col-lg-6">
            <div class="about-image" :style="`background-image: url('` + getItem(4).imageUrl + `')`"></div>
          </div>
          <div class="col-lg-6 d-flex align-items-center">
            <div class="text-section">
              <div class="about-area-content">
                <span>Supporter</span>
              </div>

              <div class="links-section pt-2">
                <div class="pt-3 pb-3 line-wrap white-space-pre-wrap" v-if="getItem(4).link_description" v-html="getItem(4).link_description"></div>
                <h2>{{getItem(4).title}}</h2>
                <div v-html="getItem(4).text"></div>
              </div>
            </div>
            </div>
        </div>
      </div>
    </section>

    <section class="p-0" id="partner">
      <div class="container-fluid">
        <div class="row">
          <div class="col-lg-6 d-flex align-items-center">
            <div class="text-section">
              <div class="about-area-content">
                <span>Partner</span>
              </div>

              <div class="links-section pt-2">
                <div class="pt-3 pb-3 line-wrap white-space-pre-wrap" v-if="getItem(5).link_description" v-html="getItem(5).link_description"></div>
                <h2>{{getItem(5).title}}</h2>
                <div v-html="getItem(5).text"></div>
              </div>
            </div>
          </div>

          <div class="col-lg-6">
            <div class="about-image" :style="`background-image: url('` + getItem(5).imageUrl + `')`"></div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
  export default {
    name: "VisitorContent",
    props: {
      visitor_data: {
        type: [Array, Object],
        default: null
      }
    },
    async mounted() {
      if (this.$route.query.go) {
        this.scrollToSection()
      } else {
        window.scrollTo(0, 0);
      }
    },
    methods: {
      async scrollToSection() {
        let section = document.getElementById(this.$route.query.go);
        if (section) {
          section.scrollIntoView({block: "center"});
        }
      },
      getItem(id) {
        let item;
        this.visitor_data.forEach(val => {
          if(val.visitor_type.id === id) {
            if (val.image) {
              val.imageUrl = val.image.data.full_url
            } else {
              val.imageUrl = require('../assets/img/default-img.jpg')
            }
            item = val
          }
        });
        return item
      }
    },
    watch: {
      '$route' () {
        this.scrollToSection()
      }
    }
  }
</script>

<style scoped lang="scss">
  .about-image {
    margin-left: -15px;
    margin-right: -15px;
    min-height: 400px;
    height: 100%;
    background-size: cover;
    background-position: center;
    img {
      height: 100%;
      width: 100%;
      object-fit: cover;
    }
  }
  .about-area-content {
    margin: 0;
  }
  .text-section {
    padding: 50px 5%;
  }
</style>
